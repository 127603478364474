import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { PluginUtils } from 'tailwindcss/types/config';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * CLX_: tailwind-merge + clsx + style modules
 * It creates a function with is going to use the css/scss module generated class names,
 * with helps of the 'clsx' library
 */
export const clx_ =
  (css: { readonly [key: string]: string } | undefined = {}) =>
  (...classes: ClassValue[]) =>
    twMerge(clsx(classes))
      .split(' ')
      .map((c) => css[c] || c)
      .join(' ');

/**
 * CLX: tailwind-merge + clsx
 */
export const clx = (...classes: ClassValue[]) => twMerge(clsx(classes));
export default clx;

/**
 * *DEV: only on client side
 * @example typeof window != 'undefined' &&
 * */
export const devConsole = (...args: any[]) => typeof window != 'undefined' && process.env.NODE_ENV == 'development' && console.log(...args);
export const debugConsole = (...args: any[]) => process.env.NEXT_PUBLIC_CONSOLE_DEBUG && console.log(...args);

/**
 * Function that helps to parse string into JSON without throwing an error.
 */
export function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    console.log('parsing error on', { value });
    return undefined;
  }
}

export const setHexOpacity = (hex: string, alpha = 1) =>
  `${hex}${Math.floor(alpha * 255)
    .toString(16)
    .padStart(2)}`;

export function hexToRgb(hex: string) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b));
  return result ? { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) } : null;
}

export const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
  array.reduce(
    (acc, value, index, array) => {
      (acc[predicate(value, index, array)] ||= []).push(value);
      return acc;
    },
    {} as { [key: string]: T[] }
  );

export const isValidUrl = (url: string | number | null = '') =>
  !!new RegExp(
    '^([a-zA-Z]+:\\/\\/)?' + // protocol // ((http|https|ftp|blob|file)+:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  ).test(String(url)); // validate fragment locator

export const sleep = (seconds: number) => new Promise<void>((resolve) => setTimeout(resolve, seconds * 1000));

export const getRandomInteger = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

export const getRealSize = (element: Element) => {
  const computedStyle = window.getComputedStyle(element);
  return {
    width: ['marginLeft', 'marginRight', 'borderLeftWidth', 'borderRightWidth'].reduce(
      (num, item) => (num += parseInt(computedStyle[item], 10)),
      element.clientWidth
    ),
    height: ['marginTop', 'marginBottom', 'borderTopWidth', 'borderBottomWidth'].reduce(
      (num, item) => (num += parseInt(computedStyle[item], 10)),
      element.clientHeight
    ),
  };
};

export const withOpacity =
  (variable: string) =>
  ({ opacityValue }: Parameters<ReturnType<PluginUtils['rgb']>>[0]) =>
    `rgba(${variable}, ${opacityValue ?? 1})`;
